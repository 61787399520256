
<template>
  <div class="address-pup">
    <van-popup
      v-model="saveAddsShow"
      round
      position="bottom"
      :style="{ height: '70%' }"
    >
      <h2 class="van-doc-demo-block__title">
        选择地址
        <span
          class="fr"
          style="color: #1497f6; font-size: 12px"
          @click="onSpanAdd"
        >新增地址</span>
      </h2>
      <van-address-list
        v-model="chosenAddressId"
        :list="addressList"
        default-tag-text="默认"
        disabled
        :switchable="true"
        add-button-text="下一步"
        @edit="onEdit"
        @select="onItemInfo"
        @add="onNextSend"
      />
    </van-popup>
    <!-- //编辑地址 -->
    <van-popup
      v-model="editAddressShow"
      round
      position="bottom"
    >

      <van-address-edit
        :address-info="editAddress"
        :area-list="areaList"
        show-delete
        @save="onSave"
        @delete="onDelete"
        @change-area="onChangeArea"
        @change-default="onChangeDefault"
      />

    </van-popup>
    <!-- //系新增地址 -->
    <!-- show-set-default ：默认地址 -->
    <van-popup
      v-model="addArdessShow"
      round
      position="bottom"
    >
      <van-address-edit
        :address-info="editAddress"
        :area-list="areaList"
        add-button-text="下一步"
        @save="onSaveDetail"
      />
    </van-popup>

    <van-popup
      v-model="sendPopup"
      round
      position="bottom"
      :style="{ height: '40%' }"
    >
      <h2 class="van-doc-demo-block__title">
        填写验证码
        <span
          class="fr"
          :style="{color:variables.textColor}"
          style=" font-size: 12px;cursor: pointer;"
          @click="getSendPaySms"
          v-html="count"
        >未收到，重新发送</span>
      </h2>
      <div style="padding: 16px 10%; text-align: center">
        <br />
        <p style="color: #595959; font-size: 12px">
          验证码已发送至手机，输入验证码确认兑换
        </p>
        <br />
        <van-field
          v-model="phoneCode"
          input-align="center"
          type="number"
          style="border-radius: 50px; background: #f6f6f6"
          placeholder="请输入验证码"
        />
      </div>

      <div style="position: fixed; bottom: 0; width: 100%">
        <van-button
          block
          color="linear-gradient(180deg, #F67743 0%, #F75D22 100%)"
          @click="onAddressSubmit"
        >
          确认兑换
        </van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import areaList from "@/utils/area.js";
import { Toast } from "vant";
import API from "@/api/http";
import variables from "@/styles/variables.scss";
import { getRegionIu } from "@/utils/common.js";
// banner图轮播组件
export default {
  props: {
    saveAddressShow: {
      type: Boolean,
      default: false,
    },
    addressList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    checkGoodls: {
      type: Object,
      default: () => {
        return {};
      },
    },
    routerType: {
      type: String,
      default: "commodity",
    },
    smsSubmitType: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      areaList,
      saveAddsShow: false, //选择地址
      addArdessShow: false, //新增地址
      editAddressShow: false,
      editAddress: {}, //新增/编辑地址
      chosenAddressId: "", //当前选中的地址id
      addressSelected: "", //当前选中的地址信息
      timer: "",
      count: "发送验证码",
      sendPopup: false, //兑换是获取验证码
      phoneCode: "", //验证码
      sendPaySmsOrder: "",
      orderId: "",
    };
  },
  computed: {
    variables() {
      return variables;
    },
  },
  watch: {
    saveAddressShow(val) {
      this.saveAddsShow = val;
    },
    saveAddsShow(val) {
      this.$emit("update:saveAddressShow", val);
    },
  },
  created() {
    this.saveAddsShow = this.saveAddressShow;
    console.log("kkk", this.editAddressShow);
  },
  methods: {
    onSpanAdd() {
      //选择地址时的 新增地址
      this.addArdessShow = true;
      this.editAddress = {};
    },
    onSaveDetail(item) {
      //新增地址的保存
      const obj = {
        id: 0,
        city: item.city,
        county: item.county,
        province: item.province,
        name: item.name,
        tel: item.tel,
        addressDetail: item.addressDetail,
        isDefault: item.isDefault,
        areaCode: item.areaCode,
      };
      API.postAddressAdd(obj).then((res) => {
        this.addArdessShow = false;
        Toast("新增成功");
        this.$emit("onSaveDetail");
        // this.getAddressList();
      });
    },
    onItemInfo(item) {
      //选中当前地址
      this.chosenAddressId = item.id;
      this.addressSelected = item;
    },
    onEdit(item) {
      const obj = {
        id: item.id,
      };

      API.postAddressDetail(obj).then((response) => {
        this.editAddressShow = true;
        const obj = {
          city: response.data.city,
          county: response.data.county,
          province: response.data.province,
          id: response.data.id,
          isDefault: response.data.isDefault,
          name: response.data.name,
          tel: response.data.tel,
          addressDetail: response.data.addressDetail,
          areaCode: response.data.areaCode,
        };
        this.editAddress = obj;
      });
    },
    onSave(item) {
      //编辑地址的保存
      const obj = {
        city: item.city,
        county: item.county,
        detailaddr: item.addressDetail,
        // 'district': item.district,
        id: item.id,
        isdefault: item.isdefault,
        province: item.province,
        recivemobile: item.tel,
        recivename: item.name,
        name: item.name,
        tel: item.tel,
        addressDetail: item.addressDetail,
        isDefault: item.isDefault,
        areaCode: item.areaCode,
      };
      API.postAddressAdd(obj).then((response) => {
        this.editAddressShow = false;
        Toast("编辑成功");
        this.$emit("onSave");
        // this.getAddressList();
      });
    },
    onDelete() {
      //编辑地址的删除
      const obj = {
        id: this.editAddress.id,
      };
      API.postAddressDel(obj).then((response) => {
        Toast("删除成功");
        this.editAddressShow = false;
        this.$emit("onDelete");
        // this.getAddressList();
      });
    },
    onChangeArea(val) {
      this.editAddress.province = val[0].name;
      this.editAddress.city = val[1].name;
      this.editAddress.county = val[2].name;
      this.editAddress.areaCode = val[2].code;
    },
    onChangeDefault(val) {
      this.editAddress.isdefault = val ? 1 : 0;
    },
    onNextSend() {
      //下一步
      if (!this.chosenAddressId) {
        return Toast("请选择地址");
      }

      this.saveAddsShow = false;
      this.addArdessShow = false;
      this.sendPopup = true;
      this.getSev();
    },
    async getSev() {
      if (!this.orderId) {
        if (this.smsSubmitType === 1) {
          await this.checkoutCart();
          try {
            await this.orderSubmit();
          } catch {
            return;
          }
        } else {
          try {
            await this.orderFastSubmit();
          } catch {
            return;
          }
        }
      }

      this.getSendPaySms();
    },

    getSendPaySms() {
      // 验证手机号

      if (!this.timer) {
        // 发送验证码

        // smsSubmitType: this.smsSubmitType,
        //  addressId: this.chosenAddressId,
        const obj = {
          orderId: Number(this.orderId),
        };

        // if (this.smsSubmitType === 1) {
        //   //购物车 兑换时，传递 商品购物车id
        //   obj.cartId = this.checkGoodls.id;
        // } else {
        //   //商城或者详情 立即购买时，传递商品产品id
        //   obj.productId = this.checkGoodls.productId;
        // }
        let TIME_COUNT = 60; // 更改倒计时时间

        API.postOrderSmsSend(obj)
          .then((res) => {
            Toast.clear();
            Toast("发送成功");
            // this.sendPaySmsOrder = response.data;

            this.timer = setInterval(() => {
              if (TIME_COUNT > 0 && TIME_COUNT <= 60) {
                TIME_COUNT--;
                this.count = `${TIME_COUNT}s`;
              } else {
                clearInterval(this.timer); // 清除定时器
                this.count = "未收到，重新发送";
                this.timer = null;
              }
            }, 1000);
          })
          .catch((err) => {
            Toast(err.errmsg);
          });
      }
    },
    async onAddressSubmit() {
      // if (this.routerType === "shop") {
      //   //购物车（立即兑换）
      //   // await this.checkoutCart();
      //   // await this.orderSubmit();
      // } else {
      //   //商品详情（立即兑换）
      //   // this.orderFastSubmit();
      // }
      // if (!this.orderId) {
      //   return;
      // }
      if (!this.phoneCode) {
        Toast("请输入验证码");
        return;
      }
      let map = {
        paysmsCode: this.phoneCode,
        orderId: Number(this.orderId),
      };

      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      API.postMobilePay(map).then((res) => {
        Toast.clear();
        Toast("兑换成功");
        this.sendPopup = false;
        clearInterval(this.timer); // 清除定时器
        this.count = "发送验证码";
        this.timer = null;
        this.phoneCode = "";
        this.$emit("onAddressSubmit");
      });
    },
    orderFastSubmit() {
      return API.orderFastSubmit({
        goodId: this.checkGoodls.goodsId,
        addressId: this.chosenAddressId,
        productId: this.checkGoodls.productId,
        number: this.checkGoodls.number,
        iu: getRegionIu(),
      }).then((res) => {
        // Toast("兑换成功");
        // this.sendPopup = false;
        // this.$emit("onAddressSubmit");
        this.orderId = res.data.orderId;
      });
    },
    checkoutCart() {
      return API.checkoutCart({
        cartId: this.checkGoodls.id,
        addressId: this.chosenAddressId,
      }).then((res) => {
        // this.chosenAddressId = res.data.checkedAddress;
      });
    },
    orderSubmit() {
      return API.orderSubmit({
        cartId: this.checkGoodls.id,
        addressId: this.chosenAddressId,
        iu: getRegionIu(),
      }).then((res) => {
        // Toast("兑换成功");
        // this.sendPopup = false;
        // this.$emit("onAddressSubmit");
        this.orderId = res.data.orderId;
        // this.$emit("onAddressSubmitPop");
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.van-address-list__bottom {
  padding: 0;
}
.van-address-list__add {
  margin: 0 auto;
}
.van-button--round {
  border-radius: 0;
}
.van-button--danger {
  background: linear-gradient(to right, #ff6034, #ee0a24);
}
.van-doc-demo-block__title {
  padding-top: 16px;
}
.van-doc-demo-block__title {
  margin: 0;
  padding: 16px;
  color: #000000;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
}
.van-grid-item__content {
  padding: 0;
}
.van-radio-group {
  padding-bottom: 50px;
}
.van-address-item {
  background: #f6f6f6;
  .van-cell {
    background: #f6f6f6;
  }
}
</style>
